import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby';
import BreadcrumbBar from 'rev.sdk.js/Templates/ProductList/BreadcrumbBar';
import FilterMenu from 'rev.sdk.js/Templates/ProductList/FilterMenu';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';
import {updateQueries} from '/src/Utils/updateQueries';
import ProductGrid from '../ProductList/ProductGrid';
import YouTubeModal from '../../Components/YouTubeModal';
import qs from 'query-string';
import {L} from '../../AppActions';

function getQueryParams() {
  if (typeof window !== undefined && window.location) {
    return qs.parse(window.location.search);
  }
}

function VideoList(props) {
  const [user] = useOutlet('user');
  const [videos, setVideos] = React.useState([]);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const params = getQueryParams();
  let cat = '';
  if (params) {
    cat = params.cat;
  }
  // adhoc build failed
  // const {cat} = params;

  function updateRoute(options) {
    const nextQueryString = updateQueries({
      keys: ['cat', 'sort', 'search'],
      params,
      options,
    });

    navigate(`/videos/${nextQueryString}`);
  }

  const catQuery = cat ? {labels: {$regex: cat}} : {};

  React.useEffect(() => {
    async function fetchData() {
      setVideos(
        await JStorageActions.fetchDocuments(
          'video',
          {...catQuery},
          null,
          null,
        ),
      );
    }

    if (user) {
      fetchData();
    }
  }, [user, cat]);

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <div className="content">
        <div style={{flexBasis: 256}}>
          <h3
            style={{
              paddingTop: 15,
              paddingBottom: 20,
              fontWeight: 600,
              letterSpacing: 2,
            }}>
            {L('product_videos')}
          </h3>
          <FilterMenu
            cat={cat}
            updateCat={(cat) => updateRoute({cat})}
            categories={'videoCategories'}
          />
        </div>
        <div>
          <div style={{padding: 'var(--basePadding)'}}>
            <BreadcrumbBar
              cat={cat}
              updateCat={(cat) => updateRoute({cat})}
              categoryDisplayMap={'videoCategoryDisplayMap'}
            />
          </div>
          <ProductGrid
            prefixPath="/videos"
            products={videos}
            onItemClick={(item) => {
              setSelectedVideo(item);
            }}
          />
        </div>
      </div>

      <YouTubeModal
        video={selectedVideo}
        onClose={() => {
          setSelectedVideo(null);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    display: flex;
  }
`;

export default VideoList;
